<template>
  <div class="container is-fluid">
    <page-header :entity="$EntitiesName.Import">
      <h1 class="title" data-test="sel-project-header">
        Import ISF
      </h1>
      <template #actions>
        <button v-if="!isImporting && entitiesToDelete.length > 0" class="button is-danger" @click="deleteEntities">Supprimer</button>
        <button v-if="!isImporting" class="button is-warning" @click="isImporting = !isImporting">Importer</button>
        <button v-if="isImporting" class="button is-warning" @click="isImporting = !isImporting">Annuler</button>
      </template>
    </page-header>

    <datatable v-if="!isImporting" :fetch="$Api.Import.fetchImportIsf" ref="datatable" :entity="$EntitiesName.ImportIsf" v-model="entitiesToDelete" enableDownload downloadEntity="import/isf">
        <div slot="project" slot-scope="{ item }" title="Projet" :sortable="true">{{ item.project }}</div>
        <div slot="market" slot-scope="{ item }" title="Marché sous-traitant" :sortable="true">{{ item.market }}</div>
        <div slot="imputation" slot-scope="{ item }" title="Imputation" :sortable="true">{{ item.imputation && item.imputation.eotp }}</div>
        <div slot="eotpDescription" slot-scope="{ item }" title="Désigation eOTP" :sortable="true">{{ item.eotpDescription }}</div>
        <div slot="subprojectName" slot-scope="{ item }" title="Sous-projet" :sortable="true">{{ item.subprojectName }}</div>
        <div slot="arcgisReference" slot-scope="{ item }" title="Référence Arcgis" :sortable="true">{{ item.arcgisReference }}</div>
        <div slot="osReference" slot-scope="{ item }" title="Référence de l'OS" :sortable="true">{{ item.osReference }}</div>
        <div slot="osStatus" slot-scope="{ item }" title="Statut de l'OS" :sortable="true">{{ item.osStatus }}</div>
        <div slot="category" slot-scope="{ item }" title="Catégorie" :sortable="true">{{ item.category }}</div>
        <div slot="subcategory" slot-scope="{ item }" title="Sous-catégorie" :sortable="true">{{ item.subcategory }}</div>
        <div slot="prestationOsId" slot-scope="{ item }" title="Id prestation" :sortable="true">{{ item.prestationOsId }}</div>
        <div slot="description" slot-scope="{ item }" title="Désignation" :sortable="true">{{ item.description }}</div>
        <div slot="unit" slot-scope="{ item }" title="Unité" :sortable="true">{{ item.unit }}</div>
        <div slot="accountingNature" slot-scope="{ item }" title="Nature comptable" :sortable="true">{{ item.accountingNature && item.accountingNature.name }}</div>
        <div slot="price" slot-scope="{ item }" title="Prix unitaire" class="has-text-right" :sortable="true">{{ item.price | priceEUR }}</div>
        <div slot="quantityOrdered" slot-scope="{ item }" title="Quantités commandées" class="has-text-right" :sortable="true">{{ item.quantityOrdered | number }}</div>
        <div slot="quantityDone" slot-scope="{ item }" title="Quantités réalisées" class="has-text-right" :sortable="true">{{ item.quantityDone | number}}</div>
        <div slot="amountOrdered" slot-scope="{ item }" title="Montant commandé" class="has-text-right" :sortable="true">{{ item.amountOrdered | priceEUR}}</div>
        <div slot="amountDone" slot-scope="{ item }" title="Montant réalisé" class="has-text-right" :sortable="true">{{ item.amountDone | priceEUR }}</div>
        <div slot="reference" slot-scope="{ item }" title="Référence" :sortable="true">{{ item.reference }}</div>
      </datatable>
    
    <div v-if="isImporting" class="panel infos">
      <div class="panel-heading has-background-primary">Copier/Coller pour importer</div>
      <div class="panel-block is-block no-border">
        <handsontable :columns="columns" :import="importIsf"/>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { isNumeric } from '../../lib/data/Validation'
export default {
  name: 'isf_import',
  data: function() {
    return {
      isImporting: false,
      entitiesToDelete: [],
      columns: [
        {
          key: 'project',
          label: 'Projet',
          required: true,
        },
        {
          key: 'market',
          label: 'Marché sous-traitant',
          required: true,
        },
        {
          key: 'imputation',
          label: 'Imputation',
          required: true,
        },
        {
          key: 'eotpDescription',
          label: 'Désignation de l\'eOTP'
        },
        {
          key: 'subprojectName',
          label: 'Sous-projet OSCAR',
        },
        {
          key: 'arcgisReference',
          label: 'Référence Arcgis',
        },
        {
          key: 'osReference',
          label: 'Référence de l\'OS'
        },
        {
          key: 'osStatus',
          label: 'Statut de l\'OS'
        },
        {
          key: 'category',
          label: 'Catégorie'
        },
        {
          key: 'subcategory',
          label: 'Sous-catégorie'
        },
        {
          key: 'prestationOsId',
          label: 'Id de la prestation',
          required: true,
        },
        {
          key: 'description',
          label: 'Désignation',
        },
        {
          key: 'unit',
          label: 'Unité',
        },
        {
          key: 'accountingNature',
          label: 'Nature comptable',
          required: true,
        },
        {
          key: 'price',
          label: 'Prix unitaire',
          validator: (value) => isNumeric(value)
        },
        {
          key: 'quantityOrdered',
          label: 'Quantités commandées',
          validator: (value) => isNumeric(value)
        },
        {
          key: 'quantityDone',
          label: 'Quantités réalisées cumulé',
          validator: (value) => isNumeric(value)
        },
        {
          key: 'amountOrdered',
          label: 'Montant commandé',
          validator: (value) => isNumeric(value)
        },
        {
          key: 'amountDone',
          label: 'Montant réalisé cumulé',
          validator: (value) => isNumeric(value)
        },
        {
          key: 'reference',
          label: 'Référence',
          required: true,
        },
      ]
    };
  },
  mounted() {
  },
  computed: {
    selectedToUrl() {
      return this.entitiesToDelete.map(object => object.id).toString();
    },
  },
  methods: {
    importIsf(data) {
      return this.$Api.Import.importIsf(data);
    },
    deleteEntities() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer ces éléments ?",
        () =>
          axios.delete(`/import/isf?selected=${this.selectedToUrl}`).then((res) => {
            if (res.data.success) {
              this.$refs.datatable.refresh()
              this.entitiesToDelete = []
            }
          })
      );
    },
  }
}
</script>
<style>

</style>
