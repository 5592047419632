export function isNumeric(n) {
  /* eslint-disable */
  var t = typeof n;

  if(t == 'number') {
    return !isNaN(n) && isFinite(n)
  }

  if(t == 'string') {
    if(!n.length) return true

    if(n.length == 1) return /\d/.test(n)
    
    return /^\s*[+-]?\s*(?:[\d\s]+(?:[,]\d+)?)\s*$/i.test(n)
  }

  if(t == 'object') {
    return !!n && typeof n.valueOf() == 'number' && !(n instanceof Date)
  }

  return false;
}